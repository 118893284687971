import React from "react";
import { gqlType } from "@emd/common";

import DataTable, { Column, TableData } from "../DataTable";

const columns: Array<Column> = [
  { id: "loadId", label: "loadId", minWidth: 170 },
  { id: "type", label: "type", minWidth: 170 },
  { id: "channel", label: "channel", minWidth: 100 },
  { id: "itu", label: "itu", minWidth: 100 },
  {
    id: "temperature",
    label: "temperature",
    minWidth: 100,
    format: (value: number) => value.toFixed(2)
  },
  // {
  //   id: "windSpeed",
  //   label: "windSpeed",
  //   minWidth: 100,
  //   format: (value: number) => value.toFixed(2)
  // },
  // {
  //   id: "windGust",
  //   label: "windGust",
  //   minWidth: 100,
  //   format: (value: number) => value.toFixed(2)
  // },
  {
    id: "rain",
    label: "rain",
    minWidth: 100,
    format: (value: number) => value.toFixed(2)
  },
  {
    id: "snow",
    label: "snow",
    minWidth: 100,
    format: (value: number) => value.toFixed(2)
  },
  { id: "timestamp", label: "timestamp", minWidth: 170, format: (value: number) => formatTimestamp(value)}
];

// Helper function to check if two dates are the same day
const isToday = (someDate: Date, today: Date) => {
  return someDate.getFullYear() === today.getFullYear() &&
         someDate.getMonth() === today.getMonth() &&
         someDate.getDate() === today.getDate();
};

const formatTimestamp = (timestamp: number): string => {
  // Create a Date object from the timestamp
  const timeObj = new Date(timestamp * 1000);

  // Define options, omitting the date portion if the time is today
  let options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',    // "14" (24-hour format by default)
    minute: '2-digit',  // "20"
    second: '2-digit',  // "27"
    hour12: false,      // Ensure it's 24-hour format
  };

  // Add the date options only if it's not today
  const now = new Date();
  if (!isToday(timeObj, now)) {
    options = {
      ...options,
      month: 'short',   // "Sep"
      day: 'numeric',   // "12"
    };
  }
  // Format the date
  const timeStr = timeObj.toLocaleString('en-US', options);
  return timeStr;
}

const formatTableEvent = (event: gqlType.EventNotification): TableData => {
  const {
    referenceId,
    loadId,
    channel,
    type,
    startTime,
    temperature,
    // windSpeed,
    // windGust,
    rain,
    snow
  } = event;
  const timestamp = startTime;  
  const result = {
    referenceId,
    loadId,
    channel,
    itu: -1,
    type,
    timestamp,
    temperature,
    // windSpeed,
    // windGust,
    rain,
    snow
  };
  return result;
};

type PropsType = {
  events: Array<gqlType.EventNotification>;
  setSelectedEvent: (arg0: string) => void;
};

const EventsTable = ({ events, setSelectedEvent }: PropsType) => {
  const formattedEvents = events.map(formatTableEvent);
  return (
    <DataTable
      columns={columns}
      data={formattedEvents}
      setSelectedRow={setSelectedEvent}
    />
  );
};

export default EventsTable;
