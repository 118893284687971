import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { analytics } from "../analytics";
import { ApolloError } from "apollo-boost";

interface ILogs {
  saveLog: (log: string, referenceId: string) => void;
  saveLogError: boolean;
  saveLogLoading: boolean;
}

const ADD_USER_AUDIT_LOG = gql`
  mutation addAuditLogComment($referenceId: String!, $comment: CommentInput!) {
    addAuditLogComment(referenceId: $referenceId, comment: $comment)
  }
`;

export const useAuditLogs = (eventType?: string): ILogs => {
  const type = eventType || "events";
  const [
    addAuditLog,
    { error: saveLogError, loading: saveLogLoading }
  ] = useMutation(ADD_USER_AUDIT_LOG, {
    refetchQueries: type === "events" ? ["eventDetails"] : undefined,
    awaitRefetchQueries: true,
    onError: (err: ApolloError) => {
      return err;
    }
  });

  const saveLog = (message: string, referenceId: string) => {
    addAuditLog({
      variables: { referenceId, comment: { message } }
    });

    analytics.auditLog({
      isSuccess: true,
      referenceId
    });
  };

  return {
    saveLog,
    saveLogLoading,
    saveLogError: Boolean(saveLogError)
  };
};
