import React, { useState } from "react";

import cx from "classnames";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import styles from "./index.module.scss";
import { Link } from "../Icons";
import { Meta, Text } from "../Type";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  EventDetailProvider,
  useEventDetailContext
} from "../../contexts/eventDetailProvider";
import EventCommentBox from "./EventCommentBox";
import { useAuditLogs } from "../../hooks/useAuditLogs";
import { TextInput } from "../Inputs";
import DateTime from "../DateTime";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#121212",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3
};

function ChildModal(image: any) {
  const [open, setOpen] = React.useState(false);
  const [enlargedImage, setEnlargedImage] = React.useState("");

  const handleOpen = () => {
    setEnlargedImage(image.image);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className={styles.clickablePreview} onClick={handleOpen}>
        {/* Enlarge Signature */}
        <img className={styles.signaturePreview} src={image.image}></img>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className={styles.enlargedModal} sx={{ ...style }}>
          <div className={styles.header}>
            <div className={styles.headerButtons}>
              <button onClick={handleClose}>X</button>
            </div>
          </div>
          <button className={styles.arrowLeft}>{"<"}</button>
          <img className={styles.enlargedImage} src={enlargedImage}></img>
          <button className={styles.arrowRight}>{">"}</button>
        </Box>
      </Modal>
    </>
  );
}

type PropsType = {
  referenceId?: string;
};

export function EventDetails({ referenceId }: PropsType) {
  const match = useRouteMatch();
  const history = useHistory();
  const { event } = useEventDetailContext();

  const copyLinkToClipboard = () => {
    // Copy the text inside the text field
    navigator.clipboard.writeText(match.url);
    alert("Event link copied to clipboard");
  };

  const toUrl = referenceId ? match.url.replace(`/${referenceId}`, "") : "/";

  const toUrlWithSearch = `${toUrl}${history.location.search}`;

  const handleClose = () => {
    history.push(toUrlWithSearch);
  };

  if (!event) {
    console.debug("No event found");
    return null;
  }
  console.debug(`Event found: ${JSON.stringify(event)}`);

  const imageNames = [
    "3DBpRms.jpg",
    "3DRMS.jpg",
    "BpRms.jpg",
    "hds_temperature.jpg",
    "mcc_temperature.jpg",
    "MHPowR.jpg",
    "PSD_Plot_1.jpg",
    "PSD_Plot_1B.jpg",
    "PSD_Plot_2.jpg",
    "PSD_Plot_2B.jpg",
    "PSD_Plot_3.jpg",
    "PSD_Plot_3B.jpg",
    "RMS.jpg",
    "StagBpRms.jpg",
    "StagMHPowR.jpg",
    "StagRMS.jpg",
    "StagTemp2.jpg",
    "Temp2.jpg",
    "Thumbs.db",
    "89.jpg",
    "DatabaseTableOfTags.png",
    "EquipmentSerialNum.txt",
    "MonitoringProtocol.jpg"
  ];
  const images = imageNames.map(name => {
    return require(`../../static/${name}`);
  });

  const { saveLog } = useAuditLogs();
  const [logInput, setLogInput] = useState("");
  const submitClassification = () => {
    if (!logInput.includes("*")) {
      alert("Error! Your input should match the following format: *1 *2");
    } else {
      saveLog(logInput, event.referenceId);
      setLogInput("");
    }
  };

  const handleLogInput = (value: string) => {
    setLogInput(value);
  };

  return (
    <div>
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: "90vw", height: "80vh", marginTop: "4vh" }}>
          <div className={styles.header}>
            <h2 id="parent-modal-title">Event Details</h2>
            <div className={styles.headerButtons}>
              <button onClick={copyLinkToClipboard}>
                <Link></Link>
              </button>
              <button onClick={handleClose}>X</button>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <div className={cx(styles.stats, styles.Wrapper)}>
                <div className={styles.Row}>
                  <Meta
                    className={styles.label}
                    component="h5"
                    size="small"
                    dark
                  >
                    Event Type
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    Leak_VelP2.Definition1
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Deployment
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.loadId}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Start Time
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    <DateTime timestamp={event.startTime * 1000} length="short" />
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Channel
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.channel}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    ITU
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    -1
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    GPS
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.coordinates.latitude}, {event.coordinates.longitude}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    KP
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.post}
                  </Text>
                </div>
                <hr className={styles.Break}></hr>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Weather
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.weatherDescription}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Temperature
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.temperature}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Wind Speed
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.windSpeed}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Wind Gust
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.windGust}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Rain
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.rain}
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    Snow
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.snow}
                  </Text>
                </div>
                <hr className={styles.Break}></hr>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    TVID
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    1275979201
                  </Text>
                </div>
                <div className={styles.Row}>
                  <Meta component="h5" size="small" dark>
                    UUID
                  </Meta>
                  <Text className={styles.Text} component="span" size="small">
                    {event.id}
                  </Text>
                </div>
              </div>
              <div className={styles.header}>
                <h2 id="parent-modal-title">Operator Classification</h2>
              </div>
              <div className={styles.classification}>
                <div className={styles.classificationInputRow}>
                  {/* <input value={logInput} onChange={handleLogInput} className = {styles.classificationInput} placeholder={"ex: *1 *2"}></input> */}
                  <TextInput
                    onChange={handleLogInput}
                    value={logInput}
                    placeholder="Tag the event"
                    label="Tag the event"
                    hideLabel
                    size="small"
                  />
                  <button
                    onClick={submitClassification}
                    className={styles.classificationSubmit}
                  >
                    Submit
                  </button>
                </div>
                <div className={styles.classificationHistory}>
                  <EventCommentBox />
                </div>
              </div>
            </div>
            <div className={styles.signatures}>
              {images.map(image => {
                return (
                  // <img className = {styles.signaturePreview} src={image}></img>
                  <ChildModal image={image} />
                );
              })}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export const EventDetailsModal = ({ referenceId }: PropsType) => {
  if (!referenceId) {
    return null;
  }
  return (
    <EventDetailProvider referenceId={referenceId}>
      <EventDetails />
    </EventDetailProvider>
  );
};
