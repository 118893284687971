import React, { useEffect, useState } from "react";
import { ApolloClient, NormalizedCacheObject } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { split } from "apollo-link";

import { useAuth } from "./AuthProvider";

import { TokenDataType } from "../types/AuthTypes";
import {
  OperationOptions,
  SubscriptionClient
} from "subscriptions-transport-ws";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL_HTTP}/graphql`,
  credentials: "include"
});

const subscriptionClient = new SubscriptionClient(
  process.env.REACT_APP_API_URL_WS || "",
  {
    reconnect: true
  }
);

const getWsAuthMiddleware = (getSessionToken: () => Promise<TokenDataType>) => {
  return {
    applyMiddleware(options: OperationOptions, next: () => void) {
      return getSessionToken().then(tokenData => {
        options.authToken = tokenData.token;

        next();
      });
    }
  };
};

const getAuthLink = (getSessionToken: () => Promise<TokenDataType>) => {
  return setContext((_, { headers }) => {
    return getSessionToken().then(tokenData => {
      const token = tokenData.token;

      // To force a request to fail add in this line:
      // const shouldFail = localStorage.getItem("auth-fail") === "true";
      //
      // Then change the Authorization header to use the "shouldFail" variable:
      // token && !shouldFail ? `Bearer ${token}` : ""
      //
      // Then toggle on requests succeeding/failing via the "auth-fail" item in local-storage
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : ""
        }
      };
    });
  });
};

let client: ApolloClient<NormalizedCacheObject>;

type PropsType = {
  children: React.ReactNode;
};

export const ApolloManager = (props: PropsType) => {
  const { getSessionToken, loading } = useAuth();
  const [clientLoading, setClientLoading] = useState(true);

  const getClientOptions = () => {
    const wsAuthMiddleware = getWsAuthMiddleware(getSessionToken);
    subscriptionClient.use([wsAuthMiddleware]);

    const wsLink = new WebSocketLink(subscriptionClient);
    const authLink = getAuthLink(getSessionToken);
    return {
      link: split(
        // split based on operation type
        // returns true to use websocket link
        // otherwise use http link
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        authLink.concat(httpLink)
      ),
      cache: new InMemoryCache()
    };
  };

  useEffect(() => {
    if (!loading) {
      client = new ApolloClient(getClientOptions());
      setClientLoading(false);
    }
    // get clientOptions is not a dependency for useEffect
    // it changes on every render
  }, [loading, getSessionToken]);

  if (clientLoading) {
    return <p>loading</p>;
  }

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
