import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// Needed to initialize react-dates to use our styles see README: https://github.com/airbnb/react-dates/blob/master/README.md
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import EventsPage from "./components/EventsPage";
import Loading from "./components/Loading";

import { useAnalytics } from "./hooks/useAnalytics";
import { usePageTracker } from "./hooks/usePageTracker";
import PageLayout from "./components/PageLayout";

const App = () => {
  useAnalytics();
  usePageTracker();

  return (
    <PageLayout>
      <Suspense fallback={<Loading heading="Loading" />}>
        <Switch>
          <Route path="/events" component={EventsPage} />
          <Redirect from="/" to="/events" />
        </Switch>
      </Suspense>
    </PageLayout>
  );
};

export default App;
