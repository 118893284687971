import React, { useState, useEffect, useContext } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { gqlType } from "@emd/common";
import { NetworkStatus, ApolloError } from "apollo-boost";

import { useAuth } from "./AuthProvider";

type PropsType = {
  children: React.ReactNode;
};

interface ILiveStatusContext {
  connectionTimeout: boolean;
  appStatus: {
    needUpdate: boolean;
    newEvent: boolean;
    activeEventCount: number;
  };
  activeAlert: boolean;
  dismissAlert: () => void;
}

// Can't use gqlType.Status because events should be initialized to undefined, not a number
type LiveStatus = Omit<gqlType.Status, "events"> & {
  events: {
    lastUpdateTimestamp?: number;
    newEventTimestamp?: number;
    activeEventCount?: number;
  };
};

interface IErrorWithStatus extends Error {
  statusCode: number;
}

export const INITIAL_LIVE_STATUS: LiveStatus = {
  events: {
    lastUpdateTimestamp: undefined,
    newEventTimestamp: undefined,
    activeEventCount: undefined
  },
  requestedOn: 0
};

const initialState: ILiveStatusContext = {
  connectionTimeout: false,
  appStatus: { needUpdate: false, newEvent: false, activeEventCount: 0 },
  activeAlert: false,
  dismissAlert: () => {
    throw new Error("LiveStatus context has not yet been initialized.");
  }
};

const FETCH_EVERY_MS = 3000;

export const LIVE_STATUS_QUERY = {
  name: "liveStatus",
  query: gql`
    query liveStatus {
      status {
        events {
          lastUpdateTimestamp
          newEventTimestamp
          activeEventCount
        }
        requestedOn
      }
    }
  `
};

const LiveStatusContext = React.createContext(initialState);

export const useLiveStatusContext = () => useContext(LiveStatusContext);

export const LiveStatusProvider = (props: PropsType) => {
  const [eventStatus, setEventStatus] = useState(INITIAL_LIVE_STATUS.events);
  const [appStatus, setAppStatus] = useState(initialState.appStatus);
  const [activeAlert, setActiveAlert] = useState<boolean>(false);

  const { logout } = useAuth();

  const { data, networkStatus } = useQuery(LIVE_STATUS_QUERY.query, {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
    notifyOnNetworkStatusChange: true,
    pollInterval: FETCH_EVERY_MS,
    onError: (error: ApolloError) => {
      if (
        error &&
        error.networkError &&
        // eslint-disable-next-line no-magic-numbers
        (error.networkError as IErrorWithStatus).statusCode === 401
      ) {
        logout();
      }
      return error;
    }
  });

  // When the event data arrives, store the new event data
  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && data) {
      const currStatus = data.status.events;

      if (
        eventStatus.newEventTimestamp !== undefined &&
        eventStatus.newEventTimestamp < currStatus.newEventTimestamp
      ) {
        setActiveAlert(true);
      }
      setAppStatus({
        needUpdate:
          eventStatus.lastUpdateTimestamp === undefined ||
          eventStatus.lastUpdateTimestamp < currStatus.lastUpdateTimestamp,
        newEvent:
          eventStatus.newEventTimestamp === undefined ||
          eventStatus.newEventTimestamp < currStatus.newEventTimestamp,
        activeEventCount: currStatus.activeEventCount
      });
      setEventStatus(currStatus);
    }
  }, [data, eventStatus, networkStatus]);

  return (
    <LiveStatusContext.Provider
      value={{
        connectionTimeout: false,
        appStatus,
        activeAlert,
        dismissAlert: () => setActiveAlert(false)
      }}
    >
      {props.children}
    </LiveStatusContext.Provider>
  );
};
