import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "airbnb-js-shims";
import { ApolloManager } from "./contexts/ApolloManager";
import { LiveStatusProvider } from "./contexts/LiveStatusProvider";
import ModalProvider from "./contexts/ModalProvider";

import Auth from "./components/Auth";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorScreen from "./components/ErrorScreen";

import * as serviceWorker from "./serviceWorker";

import "./index.scss";

const root = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
    <ErrorBoundary errorComponent={ErrorScreen}>
      <Auth>
        <ApolloManager>
          <LiveStatusProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </LiveStatusProvider>
        </ApolloManager>
      </Auth>
    </ErrorBoundary>
  </BrowserRouter>,
  root
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
