import { useMemo } from "react";
import qs from "qs";
import { useLocation, useHistory } from "react-router-dom";

// List of allowed Query Params
export enum QueryParams {
  start,
  end,
  pipelines,
  types,
  sortBy,
  swLat,
  swLong,
  neLat,
  neLong,
  limit,
  offset
}

export interface IQueryParamsType {
  [key: string]: string | Array<string> | undefined | number;
}

export const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    if (location.search) {
      return qs.parse(location.search.slice(1));
    }

    return {};
  }, [location.search]);

  const setQueryParams = (
    params: IQueryParamsType,
    historyMethod: "replace" | "push"
  ) => {
    const currentQueryParams = qs.parse(window.location.search.slice(1));
    const allParams = { ...currentQueryParams, ...params };

    const filteredParams = Object.keys(allParams).reduce((acc, paramKey) => {
      if (allParams[paramKey] !== undefined) {
        return { ...acc, [paramKey]: allParams[paramKey] };
      }
      return acc;
    }, {});
    const historyChangeMethod =
      historyMethod === "replace" ? history.replace : history.push;

    historyChangeMethod({
      pathname: window.location.pathname,
      search: `?${qs.stringify(filteredParams, {
        arrayFormat: "comma",
        encode: false
      })}`
    });
  };

  return {
    queryParams,
    setQueryParams
  };
};
