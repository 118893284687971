import React from "react";
import {
  formatDate,
  formatTime,
  shortDate,
  longDate
} from "../../helpers/formatDate";

type PropsType = {
  length: "dateOnly" | "timeOnly" | "short" | "long";
  timestamp: number;
};

const DateTime = ({ length, timestamp }: PropsType): JSX.Element => {
  const timeOptions = {
    timezoneAbbr: "MST",
    utcOffset: "-7"
  };
  if (length === "dateOnly") {
    return <>{timeOptions ? formatDate(timestamp, timeOptions) : null}</>;
  } else if (length === "timeOnly") {
    return <>{timeOptions ? formatTime(timestamp, timeOptions) : null}</>;
  } else if (length === "short") {
    return <>{timeOptions ? shortDate(timestamp, timeOptions) : null}</>;
  } else {
    return <>{timeOptions ? longDate(timestamp, timeOptions) : null}</>;
  }
};

export default DateTime;
