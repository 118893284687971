import { useEffect, useState } from "react";
import { useQueryParams } from "../hooks/useQueryParams";
import { useLocation } from "react-router-dom";

import { analytics } from "../analytics";

export const usePageTracker = () => {
  const location = useLocation();
  const { queryParams } = useQueryParams();
  const [lastPathTracked, setLastPathTracked] = useState<string>();

  useEffect(() => {
    const basePath = location.pathname.split("/")[1];
    // eslint-disable-next-line no-magic-numbers
    const eventName: string | undefined = location.pathname.split("/")[2];
    // @ts-expect-error
    const activePage: string | undefined = queryParams.page;

    const track = (data?: {
      page: string | undefined;
      eventId: string | undefined;
    }) => {
      analytics.pageView(`/${basePath}`, data);
      setLastPathTracked(location.pathname);
    };

    if (location.pathname !== lastPathTracked) {
      track({ page: activePage, eventId: eventName });
    }
  }, [lastPathTracked, location.pathname, queryParams.page]);
};
