import React, { useContext, useEffect } from "react";
import gql from "graphql-tag";
import { gqlType } from "@emd/common";

import { useEvents } from "../hooks/useEvents";
import { useLiveStatusContext } from "./LiveStatusProvider";
import formatEvent from "../helpers/formatEvent";

interface IEventDetailContext {
  event?: gqlType.EventNotification;
}

const initialContext: IEventDetailContext = {
  event: undefined
};

const eventDetailContext = React.createContext(initialContext);
const ContextProvider = eventDetailContext.Provider;

type PropsType = {
  referenceId: string;
  children: React.ReactNode;
};

const EVENT_DETAIL_FRAGMENT = gql`
  fragment EventFragments on EventNotification {
    id
    referenceId
    loadId
    type
    post
    coordinates {
      latitude
      longitude
      __typename
    }
    tagged
    tags
    startTime
    channel
    weatherDescription
    temperature
    windSpeed
    windGust
    rain
    snow
    signatures {
      id
      name
      previewFileUri
      enlargedFileUri
      eventId
      timestamp
      createdAt
      updatedAt
      __typename
    }
    auditLogs {
      id
      eventId
      createdAt
      comment {
        author
        type
        message
        systemCreated
        __typename
      }
      __typename
    }
    __typename
  }
`;

export const EventDetailProvider = (props: PropsType) => {
  const { appStatus } = useLiveStatusContext();

  const {
    currentLiveEvents: { status, events },
    reFetchQuery
  } = useEvents({
    queryName: "eventDetails",
    queryFragment: EVENT_DETAIL_FRAGMENT,
    filters: { referenceId: props.referenceId }
  });

  useEffect(() => {
    if (appStatus.needUpdate) {
      reFetchQuery();
    }
  }, [appStatus.needUpdate, props.referenceId, reFetchQuery]);

  const event =
    events && events.length > 0 && status === "connected"
      ? events[0]
      : initialContext.event;

  return (
    <ContextProvider
      value={{
        event: event ? formatEvent(event) : undefined
      }}
    >
      {props.children}
    </ContextProvider>
  );
};

export const useEventDetailContext = () => useContext(eventDetailContext);
