import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import cx from "classnames";
import styles from "./index.module.scss";

import { offset, eventsLimit } from "../../helpers/pagination";
import { useQueryParams } from "../../hooks/useQueryParams";

import { useEvents } from "../../hooks/useEvents";
import { useLiveStatusContext } from "../../contexts/LiveStatusProvider";
import EventsTable from "../EventsTable";
import { EventDetailsModal } from "../EventDetails";
import { Search } from "../Icons";

const EventsPage = () => {
  const match = useRouteMatch();
  const slugMatch = useRouteMatch<{ referenceId?: string }>(
    `${match.url}/:referenceId`
  );
  const history = useHistory();

  const { appStatus } = useLiveStatusContext();

  const { queryParams } = useQueryParams();
  // @ts-expect-error
  const page = parseInt(queryParams.page, 10);
  const [toggleValue, setToggleValue] = useState(true)
  const [eventFilters, setEventFilters] = useState({});
  const { currentLiveEvents, reFetchQuery, loading } = useEvents({
    queryName: "liveEvents",
    filters: {
      limit: eventsLimit,
      offset: location.pathname.includes("/events")
        ? offset(page, eventsLimit)
        : 0,
      ...eventFilters
    }
  });

  useEffect(() => {
    if (appStatus.needUpdate) {
      reFetchQuery();
    }
  }, [appStatus.needUpdate, reFetchQuery]);

  const selectedEvent =
    slugMatch && slugMatch.params ? slugMatch.params.referenceId : undefined;

  const selectEvent = (referenceId: string) => {
    history.push(`${match.url}/${referenceId}${history.location.search}`);
  };

  const [inputValue, setInputValue] = useState('');

  const searchPattern =  /(\w+)([<>!=]*=?)([^,\s]*)/g;
  const handleSearch = () => {
    // Apply the regex pattern
    let match;

    const searchEventFilters = {}
    const timeRange = {start: undefined, end: undefined}
    while ((match = searchPattern.exec(inputValue)) !== null) {
      const key = match[1];
      const operand = match[2];
      const value = match[3];
      if (key === "timestamp") {
        if (operand === ">=") {
          timeRange.start = value
        } else {
          timeRange.end = value
        }
      } else if (key === 'channel') {
        searchEventFilters[key] = parseInt(value);
      } else if (key === 'tags') {
        searchEventFilters[key] = JSON.parse(value);
      } else {
        searchEventFilters[key] = {operand, value};
        searchEventFilters[key] = value;
      }

    }
    setEventFilters({
      ...searchEventFilters,
      // timeRange: {
      //   start: timeRange.start,
      //   end: timeRange.end
      // }
    });
    console.debug(JSON.stringify(searchEventFilters))
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }

  // Handler for input change
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleToggle = (event) => {
    setToggleValue(!event.target.checked);
    console.log(toggleValue)
  }

  const showSearchTips = () => {
    alert("Search using any combination of the following parameters. Only equalities are supported.\nV2 will add a form and greater/less than.\n\ntimestamp=number (unix)\nloadId=string\ntype=string\nchannel=number\nitu=number\ntags=array (matches ANY not ALL)\ntemperature=number\nrain=number\nsnow=number")
  }
  
  let subtitle
  if (inputValue === "") {
    subtitle = `${currentLiveEvents.totalEvents} Pending Events`
  } else {
    subtitle = "Search Results"
  }
  
    return (
    <>
      <div className={styles.bodyHeader}>
        <div className={styles.eventsTitle}>
          {loading?
            "Loading...":
            subtitle
          }
        </div>
        <div className={styles.searchContainer}>
          <input
            className={styles.searchInput}
            value={inputValue} // Bind the input value to state
            onChange={handleChange} // Update the state on change
            onKeyDown={handleKeyDown}
            placeholder={"ex/ Deployment=Norlite.Load1,Temperature<=0,ITU=30"}
          ></input>
          <button className={styles.searchSubmit} onClick={handleSearch}>
            <Search></Search>
          </button>
          <button className={styles.searchSubmit} onClick={showSearchTips}>
            ?
          </button>
        </div>
        <div className={styles.toggleTitle}>Hide classified events</div>
        <label className={styles.switch}>
          <input type="checkbox" onChange={handleToggle}/>
          <span className={cx(styles.slider, styles.round)}></span>
        </label>
      </div>
      <EventsTable
        events={currentLiveEvents.events}
        setSelectedEvent={selectEvent}
      />
      {selectedEvent && <EventDetailsModal referenceId={selectedEvent} />}
    </>
  );
};

export default EventsPage;
