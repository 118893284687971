import moment from "moment";
import {
  SECONDS_PER_DAY,
  HOURS_PER_DAY,
  MILLISECONDS_IN_SECONDS,
  SECONDS_PER_MINUTE,
  MINUTES_PER_HOUR,
  ISO_STR_HOUR_INDEX,
  ISO_STR_SECOND_END_INDEX
} from "../helpers/constants";

type OptionsType = { utcOffset: string; timezoneAbbr: string };

const dateFormat = "MMM D, YYYY";
export const formatDate = (unixTime: number, options: OptionsType) => {
  const formattedDate = moment(unixTime)
    .utcOffset(options.utcOffset)
    .format(dateFormat);

  return formattedDate;
};

const timeFormat = "HH:mm:ss";
export const formatTime = (unixTime: number, options: OptionsType) => {
  const formattedTime = moment(unixTime)
    .utcOffset(options.utcOffset)
    .format(timeFormat);

  return `${formattedTime}`;
};

const shortFormat = "MMM D";
export const shortDate = (unixTime: number, options: OptionsType) => {
  const formattedDate = moment(unixTime)
    .utcOffset(options.utcOffset)
    .format(shortFormat);

  return `${formattedDate}, ${formatTime(unixTime, options)}`;
};

export const longDate = (unixTime: number, options: OptionsType) => {
  return `${formatDate(unixTime, options)}, ${formatTime(unixTime, options)}`;
};

export const graphDate = (unixTime: number, options?: OptionsType) => {
  let utc = moment(unixTime);
  if (options) utc = utc.utcOffset(options.utcOffset);
  const time = utc.format("HH:mm");
  const date = utc.format("MMM DD");
  return `${time}<br>${date}`;
};

// based on this article https://dev.to/alexparra/js-seconds-to-hh-mm-ss-22o6
export const secondsToHms = (seconds: number) => {
  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * MILLISECONDS_IN_SECONDS)
    .toISOString()
    .substring(ISO_STR_HOUR_INDEX, ISO_STR_SECOND_END_INDEX);
  return hms.replace(/^(\d+)/, h => `${Number(h) + days * HOURS_PER_DAY}`);
};

export const formatElapsedTime = (unixTime: number) => {
  const elapsed = Date.now() - unixTime;
  const minutes = Math.round(
    elapsed / MILLISECONDS_IN_SECONDS / SECONDS_PER_MINUTE
  );
  const hours = Math.round(minutes / MINUTES_PER_HOUR);
  const days = Math.round(hours / HOURS_PER_DAY);

  if (days > 0) {
    return `${days}d`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes} min`;
  } else {
    return "<1 min";
  }
};
