import React from "react";
import { useAuth } from "../../contexts/AuthProvider";
import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
};

const PageLayout = ({ children }: PropsType) => {
  // const [navText, setNavText] = React.useState('Home -> Monitoring')
  const navText = "Home -> Event Monitoring";
  const { user } = useAuth();

  return (
    <div className={styles.App}>
      <header className={styles.header}>
        <div className={styles.navSteps}>{navText}</div>
        <div className={styles.user}>
          {user
            ? user.email
              ? user.email.replace("@hifieng.com", "")
              : "Not Logged In"
            : "Not Logged In"}
        </div>
      </header>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default PageLayout;
