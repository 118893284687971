import React from "react";
import { useEventDetailContext } from "../../../contexts/eventDetailProvider";
import { gqlType } from "@emd/common";
import styles from "./index.module.scss";
import { Text } from "../../Type";
import DateTime from "../../DateTime";

const EventCommentBox = () => {
  const edc = useEventDetailContext();
  const event: gqlType.EventNotification | undefined = edc.event;

  return event ? (
    <ul>
      {event.auditLogs &&
        event.auditLogs.map(log => {
          if (log.comment.type !== "Comment") {
            return null;
          }
          return (
            <div className={styles.classificationRow}>
              <Text size="small" component="span">
                <DateTime timestamp={log.createdAt * 1000} length="short" />
              </Text>
              <Text size="small" component="span">
                {log.comment.message ? log.comment.message.slice(0, 5) : null}
              </Text>
              <Text size="small" component="span">
                {log.comment.author.replace("@hifieng.com", "")}
              </Text>
            </div>
          );
        })}
    </ul>
  ) : null;
};

export default EventCommentBox;
